import { gql } from "@apollo/client"

export const GET_OPEN_ROLES = gql`
  query getOpenRoles {
    open_roles {
      role_slug
      role_title
    }
  }
`

export const GET_OPEN_ROLE_DETAIL = gql`
  query openRoleDetail($roleSlug: String!) {
    open_roles_by_pk(role_slug: $roleSlug) {
      role_slug
      role_title
      role_overview
      open_role_responsibilities {
        responsibility
      }
      open_role_basic_requirements {
        basic_requirement
        nice_to_have
      }
    }
  }
`
