import React from "react"
import OpenRolesGrid from "../components/OpenRolesGrid"
import RoleDescription from "../components/RoleDescription"
import { GET_OPEN_ROLE_DETAIL } from "../queries/getOpenRoles"

import { useQuery } from "@apollo/client"
import Loading from "../components/Loading"

const Role = ({ roleSlug }) => {
  const { loading, data, error } = useQuery(GET_OPEN_ROLE_DETAIL, {
    variables: {
      roleSlug: roleSlug,
    },
  })

  if (loading) {
    return <Loading />
  }
  if (error || data.open_roles_by_pk === null) {
    return <OpenRolesGrid />
  }
  return (
    <RoleDescription
      roleTitle={data.open_roles_by_pk.role_title}
      roleOverview={data.open_roles_by_pk.role_overview}
      responsibilities={data.open_roles_by_pk.open_role_responsibilities.map(
        r => r.responsibility
      )}
      basicRequirements={data.open_roles_by_pk.open_role_basic_requirements.map(
        r => r
      )}
    />
  )
}

export default Role
