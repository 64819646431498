import React from "react"
import SEO from "../SEO"
import styles from "./styles.module.scss"

const RoleDescription = ({
  roleTitle,
  roleOverview,
  responsibilities,
  basicRequirements,
}) => {
  const Preamble = () => (
    <section className={styles.jobSection}>
      <h2 className={`small-header ${styles.subheader}`}>
        About NeighborShare
      </h2>
      <p className="small-body-copy-serif">
        1 in 3 Americans can’t afford a $400 emergency.
      </p>
      <p className="small-body-copy-serif">
        <a href={"https://nbshare.org/"} target={"_blank"}>
          NeighborShare
        </a>{" "}
        is a nonprofit startup that fills a critical gap in our ecosystem,
        getting “just in time” resources to resource-strapped Americans by
        connecting neighbors who need help with neighbors who want to help.
      </p>
      <p className="small-body-copy-serif">
        How do we do it? We partner with front line staff at trusted nonprofits
        — case workers, social workers, etc. who are already on the ground in
        communities —to identify pivotal needs of $400 or less that would
        otherwise go unmet… needs that are otherwise slipping through the cracks
        and sending families into preventable crises.
      </p>
      <p className="small-body-copy-serif">
        We believe most people would lend a hand to a neighbor in crisis – but
        identifying those real-time needs is next to impossible for the average
        citizen. So NeighborShare makes the connection for you, indexing
        hundreds of emergent critical needs in real-time. When local options
        have run out, we use our platform to connect those critical needs with
        people who want to help, effectively removing the red tape to accessing
        real-time help.
      </p>
      <p className="small-body-copy-serif">
        Through NeighborShare, everyday donors can give with confidence, knowing
        their contributions will go directly to real households with real needs,
        and the families helped stay on track. Neighbors helping neighbors.
      </p>
      <p className="small-body-copy-serif">
        Founded during the pandemic, NeighborShare has quickly scaled our
        footprint to a national network of more than 60 partner organizations,
        directly helping over 8,000 neighbors across 25+ states. We have largely
        been powered by a{" "}
        <a href={"https://nbshare.org/team"} target={"_blank"}>
          dedicated team
        </a>{" "}
        of volunteers who are experts in their fields—business strategists,
        engineers, product designers, marketers. We are also led by a Board of
        industry leaders from world-class companies like Goldman Sachs,
        VistaPrint, and Blackstone. Actively building toward a true national
        movement, our team is driven by a generosity of spirit and a shared
        vision of what the future of philanthropy looks like.
      </p>
    </section>
  )

  const Contact = () => (
    <section className={styles.jobSection}>
      <h2 className={`small-header ${styles.subheader}`}>
        Contact Information
      </h2>
      <p className="small-body-copy-serif">
        Please send a brief cover note and resume to{" "}
        <a href={"mailto:kelli@nbshare.org"}>kelli@nbshare.org</a> if you are
        interested in exploring this opportunity.
      </p>
    </section>
  )

  const Responsibilities = ({ items }) => (
    <section className={styles.jobSection}>
      <h2 className={`small-header ${styles.subheader}`}>Responsibilities</h2>
      <ul className="small-body-copy-serif">
        {items.map(item => (
          <li key={item}>{item}</li>
        ))}
      </ul>
    </section>
  )

  const RoleOverview = () => (
    <section className={styles.jobSection}>
      <h2 className={`small-header ${styles.subheader}`}>Role Overview</h2>
      <p className="small-body-copy-serif">{roleOverview}</p>
    </section>
  )

  const BasicRequirements = ({ items, niceToHaveItems }) => (
    <section className={styles.jobSection}>
      <h2 className={`small-header ${styles.subheader}`}>Basic Requirements</h2>
      <ul className="small-body-copy-serif">
        {items.map(item => (
          <li key={item.basic_requirement}>{item.basic_requirement}</li>
        ))}
        {niceToHaveItems.length > 0 && <li>Nice to haves:</li>}
        <ul className="small-body-copy-serif">
          {niceToHaveItems.map(item => (
            <li key={item.basic_requirement}>{item.basic_requirement}</li>
          ))}
        </ul>
      </ul>
    </section>
  )

  return (
    <>
      <SEO title={roleTitle} description=" " card="/join-our-team.png" />

      <article className="wrapper wrapper--left">
        <h1 className={`header ${styles.roleTitle}`}>{roleTitle}</h1>
        <Preamble id="description" />
        <RoleOverview id="overview" />
        <Responsibilities id="responsibilities" items={responsibilities} />
        <BasicRequirements
          id="requirements"
          items={basicRequirements.filter(item => !item.nice_to_have)}
          niceToHaveItems={basicRequirements.filter(item => item.nice_to_have)}
        />
        <Contact id="contact" />
      </article>
    </>
  )
}

export default RoleDescription
