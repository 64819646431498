import React from "react"
import GridSection from "../GridSection"
import ButtonCustom from "../ButtonCustom"
import Icon from "../Icon"

import styles from "./styles.module.scss"
import { GET_OPEN_ROLES } from "../../queries/getOpenRoles"

import { navigate } from "@reach/router"
import { useQuery } from "@apollo/client"

const RoleCard = ({ roleName, slug }) => {
  return (
    <article className={styles.card}>
      <div className={styles.information}>
        <Icon aesthetic="simple" icon="unclassified" />
        <h2 className="small-header">{roleName}</h2>
      </div>
      <ButtonCustom
        hierarchy="primary"
        size="medium"
        role="link"
        onClick={() => navigate(`/role/${slug}`)}
      >
        Learn More
      </ButtonCustom>
    </article>
  )
}

const LastRoleCard = () => {
  return (
    <article className={styles.lastCard}>
      <div className={styles.information}>
        <h2 className="small-header">Something else?</h2>
        <p className={`small-body-copy ${styles.description}`}>
          Do you have skills not listed here that you think might be useful?
        </p>
      </div>
      <ButtonCustom
        hierarchy="primary"
        size="medium"
        role="link"
        onClick={() => navigate("mailto:kyle@nbshare.org")}
      >
        Get in touch
      </ButtonCustom>
    </article>
  )
}

const OpenRolesGrid = () => {
  const { data } = useQuery(GET_OPEN_ROLES)
  const openRoles = data !== undefined && data !== null ? data.open_roles : []

  return (
    <GridSection title={"Join our team"} bgColor="white" id="join-us">
      <ul>
        {openRoles.map(role => (
          <li key={role.role_slug}>
            <RoleCard roleName={role.role_title} slug={role.role_slug} />
          </li>
        ))}
        <li>
          <LastRoleCard />
        </li>
      </ul>
    </GridSection>
  )
}

export default OpenRolesGrid
